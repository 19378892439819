import PropTypes from 'prop-types'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import swal from 'sweetalert'

export const FormularioAgenda = () => {

    const { register, formState: { errors }, handleSubmit } = useForm();

    const AgendaDefault = {
        Fecha: '',
        Profecional: '',
        Hora: '',
        Producto:''
    }
    const [Agenda, setAgenda] = useState(AgendaDefault)

    const [Agendas, setAgendas] = useState([])

    const Onsubmit = (Agenda) => {
        if (Agendas.find(element => element.Profecional + element.Fecha + element.Hora  === Agenda.Profecional.trim() + Agenda.Fecha.trim() + Agenda.Hora.trim() ) != undefined)
            return swal(
                'Error',
                'Hora ya se encuentra ocupada',
                'error'
            );

        setAgendas([...Agendas, Agenda]);
        setAgenda(AgendaDefault);
        return swal(
            'OK',
            'Agenda creada correctamente',
            'success'
        );
    }

    const OnChange = (event) => {
        setAgenda({
            ...Agenda,
            [event.target.name]: event.target.value
        });
    }

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                    <h1 class="text-center header">Formulario Agenda</h1>
                        <form onSubmit={handleSubmit(Onsubmit)}>
                            <div className="form-group">
                                 <select name='Profecional'
                                    onChange={OnChange}
                                    className="form-control"
                                    {...register('Profecional', {
                                        required: true
                                    })}>
                                <option value="">Seleccion Profecional</option>
                                <option value="Richard">Richard</option>
                                <option value="Elvin">Elvin</option>
                                <option value="Jona">Jona</option>
                            </select>
                            {errors.Profecional?.value === 'required' && <p class="MessageError">El campo Profecional es requerido</p>}
                            </div>
                            <div className="form-group">
                                 <select name='Producto'
                                    onChange={OnChange}
                                    className="form-control"
                                    {...register('Producto', {
                                        required: true
                                    })}>
                                    <option value="">Selecciona Producto</option>
                                    <option value="Cualquier Wea 1">Cualquier Wea 1</option>
                                    <option value="Cualquier Wea 2">Cualquier Wea 2</option>
                                    <option value="Cualquier Wea 3">Cualquier Wea 3</option>
                                    <option value="Cualquier Wea 4">Cualquier Wea 4</option>
                                    <option value="Cualquier Wea 5">Cualquier Wea 5</option>
                                </select>
                                {errors.Producto?.value  === 'required' && <p class="MessageError">El campo Producto es requerido</p>}
                            </div>
                            <div className="form-group">
                                <input
                                    type="date"
                                    placeholder="Fecha"
                                    name='Fecha'
                                    className="form-control"
                                    {...register('Fecha', {
                                        required: true
                                    })}
                                />{errors.Fecha?.value  === 'required' && <p class="MessageError">El campo Fecha es requerido</p>}
                            </div>
                            <div className="form-group">
                                 <select name='Hora'
                                    onChange={OnChange}
                                    className="form-control"
                                    {...register('Hora', {
                                        required: true
                                    })}>
                                <option value="">Selecciona Hora</option>
                                <option value="20:00">20:00</option>
                                <option value="16:00">16:00</option>
                                <option value="17:00">17:00</option>
                                <option value="18:00">18:00</option>
                                <option value="16:00">16:00</option>
                            </select>
                            {errors.Hora?.value === 'required' && <p class="MessageError">El campo Hora es requerido</p>}
                            </div>
                           
                            <div className="form-group">
                                <button type='submit' className="btn btn-primary">Enviar</button></div>
                        </form>
                    </div>
                    <div className="col-sm-12">
                        <table className="table table-striped table-bordered" key='TableAgendas'>
                            <thead>
                                <tr>
                                    <th>Profecional</th>
                                    <th>Producto</th>
                                    <th>Fecha</th>
                                    <th>Hora</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    Agendas.map(Agenda =>
                                        <>
                                            <tr key={Agenda.Profecional + Agenda.Fecha+ Agenda.Hora + Agenda.Producto }>
                                                <td>{Agenda.Profecional}</td>
                                                <td>{Agenda.Producto}</td>
                                                <td>{Agenda.Fecha}</td>
                                                <td>{Agenda.Hora}</td>
                                            </tr>
                                        </>)
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

FormularioAgenda.protoTypes = {
    Fecha: PropTypes.string.isRequired,
    Profecional: PropTypes.string.isRequired,
    Hora: PropTypes.string.isRequired,
    Producto:PropTypes.string.isRequired
}