import './App.css';
import { FormularioAgenda } from './components/FormularioAgenda'
import { Nbar } from './components/Nbar'

function App() {
  return (
    <>
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1"/>
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css"/>
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.6.3/jquery.min.js"></script>
    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
    <Nbar/>
    <FormularioAgenda /> 
    </>
      
  );
}

export default App;
